<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item menu-item-coin"
    :class="{
      active: isActive,
      disabled: item.disabled
    }"
  >
    <b-link v-bind="linkProps" class="d-flex align-items-center">
      <span class="icon-span">
        <feather-icon :icon="item.icon || 'CircleIcon'" />

        <!-- <Payment class="custom-icon" v-if="item.icon == 'Payment'" />
        <Security class="custom-icon" v-if="item.icon == 'Security'" />
        <Verification class="custom-icon" v-if="item.icon == 'Verification'" />
        <Wallet class="custom-icon" v-if="item.icon == 'Wallet'" />
        <Order class="custom-icon" v-if="item.icon == 'Order'" />
        <BuySell class="custom-icon buy-sell" v-if="item.icon == 'BuySell'" />
        <MyAds class="custom-icon" v-if="item.icon == 'MyAds'" />
        <Fees class="custom-icon fees" v-if="item.icon == 'Fees'" />
        <RefData class="custom-icon refdata" v-if="item.icon == 'RefData'" />
        <Dispute class="custom-icon dispute" v-if="item.icon == 'Dispute'" /> -->
      </span>

      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from "@core/libs/acl";
import { BLink, BBadge } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";
// import Payment from "@/assets/rashi-coin/payment.svg";
// import Security from "@/assets/rashi-coin/security.svg";
// import Verification from "@/assets/rashi-coin/verification.svg";
// import Wallet from "@/assets/rashi-coin/wallet.svg";
// import Order from "@/assets/rashi-coin/order.svg";
// import BuySell from "@/assets/rashi-coin/buysell.svg";
// import MyAds from "@/assets/rashi-coin/myads.svg";
// import Fees from "@/assets/rashi-coin/fees.svg";
// import RefData from "@/assets/rashi-coin/refData.svg";
// import Dispute from "@/assets/rashi-coin/dispute.svg";
export default {
  components: {
    BLink,
    BBadge
    // Payment,
    // Security,
    // Verification,
    // Wallet,
    // Order,
    // BuySell,
    // MyAds,
    // Fees,
    // RefData,
    // Dispute
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    );
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t
    };
  }
};
</script>
<style lang="scss">
.active {
  .fees,
  .refdata {
    path {
      fill: #fff;
    }
  }
  .buy-sell path {
    stroke: #fff;
  }
}
</style>
