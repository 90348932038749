<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <!-- <notification-dropdown /> -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <!-- <p class="user-name font-weight-bolder mb-0">John Doe</p>
            <span class="user-status">Admin</span> -->
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :text="firstName"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item
          :to="{ name: 'profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="20" icon="UserIcon" class="mr-50" />
          <span>{{ $t("profile.Profile") }}</span>
        </b-dropdown-item> -->

        <b-dropdown-item
          :to="{ name: 'set-new-password' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="20" icon="LockIcon" class="mr-50" />
          <span>{{ $t("profile.ChangePassword") }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <Set2FA :modalVisible="isModalVisible" />
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Locale from "./Locale.vue";

import { mapState, mapMutations } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import APIService from "@/libs/api/api";

import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Locale,

    DarkToggler,
    BButton
  },
  data() {
    return {
      resetSystemPassword: false,
      isLoading: false,
      firstName: "",
      isModalVisible: false
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  },
  async created() {
    this.getRefData();
    this.userInfo();
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.firstName = userInfo.email?.[0] || "";

      if (!userInfo.emailVerified) {
        //  show pop
        this.resetSystemPassword = false;
      } else {
        // hide pop
        this.resetSystemPassword = false;

        if (!userInfo.totpLinked) {
          const shown2faAlready = JSON.parse(localStorage.getItem("showed2fa"));
          if (shown2faAlready !== "true") {
            this.toggleModal();
            localStorage.setItem("showed2fa", JSON.stringify("true"));
          }
        }
      }
    }
  },
  computed: {
    ...mapState("user", ["user"])
  },
  methods: {
    ...mapMutations("user", ["setRefData", "setUser"]),
    toggleResendEmailPop(props) {
      this.resendEmail = props;
    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    async userInfo() {
      try {
        const res = await new APIService().api(
          { method: "GET", url: `auth/user/info` },
          {},
          { isLoading: true }
        );
        if (res && res.userInfo && !res.userInfo.emailVerified) {
          this.resendEmail = true;
          localStorage.setItem("userInfo", JSON.stringify(res.userInfo));
          this.setUser(res.userInfo);
        } else if (res && res.userInfo && res.userInfo.emailVerified) {
          this.resendEmail = false;
          this.setUser(res.userInfo);
          localStorage.setItem("userInfo", JSON.stringify(res.userInfo));
        } else if (res && res.error && res.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.error.message,
              icon: "EditIcon",
              variant: "danger"
            }
          });
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error,
            icon: "EditIcon",
            variant: "danger"
          }
        });
      }
    },
    async getRefData() {
      try {
        this.isLoading = true;
        const res = await new APIService().api(
          { method: "GET", url: `p2p/admin/refdata` },
          {},
          {}
        );
        if (res && res.result && res.result.requestStatus === "SUCCESS") {
          this.setRefData(res);
        } else if (
          res &&
          res.result &&
          res.result.errors &&
          res.result.errors[0].message
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.result.errors[0].message,
              icon: "EditIcon",
              variant: "danger"
            }
          });
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error,
            icon: "EditIcon",
            variant: "danger"
          }
        });
      }
      this.isLoading = false;
    },
    async logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem("userInfo");
      localStorage.removeItem("showed2fa");
      this.$router.push({
        name: "auth-login",
        params: { lang: this.lang || undefined }
      });
    }
  }
};
</script>
